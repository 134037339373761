import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {Auth} from 'aws-amplify'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // single source of data
        files: [],
        path: '/',
        user: {},
        noPassLogin:false,
        hideUser:false,
        // TODO replace dict with language files
        dict : {}, 
        sortKey: 'name',
        sortDirAsc: true,
        jwt: {
            'token': localStorage.getItem('token')
        },
        settings: {}
    },
    getters: {
        // reusable data accessors
        isAuthenticated(state) {
            if (!state.jwt.token || state.jwt.token.split('.').length < 3) {
                return false
            }
            const data = JSON.parse(atob(state.jwt.token.split('.')[1]))
            const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
            const now = new Date()
            return now < exp
        }
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setJwtToken(state, payload) {
            localStorage.token = payload.token
            state.jwt = payload
        },
        setPath(state, payload) {
            state.path = payload.path
        },
        setFiles(state, payload) {
            state.files = payload.files
        },
        setSortKey(state, key) {
            state.sortKey = key
        },
        setSortDirAsc(state, dirAsc) {
            state.sortDirAsc = dirAsc
        }
    },
    actions: {
        // login(context, userData) {
        //     context.commit('setUserData', {
        //         userData
        //     })
        //     return authenticate(userData)
        //         .then(response => context.commit('setJwtToken', {
        //             jwt: response.data
        //         }))
        //         .catch(error => {
        //             console.log('Error Authenticating: ', error)
        //             alert("Errore durante l'autenticazione")
        //             EventBus.$emit('failedAuthentication', error)
        //         })
        // },
        listDir(context, folder) {
            return axios.post("/list",{
                "path":(folder+"/").replace("//","/"),
                "type":'dir'
                },{
                headers: {
                    "Authorization":context.state.jwt.token,
                    "Content-Type":"application/json"
                }
            })
            .then(response=>{
                context.commit("setFiles",response.data)
                context.commit('setPath', {'path': folder.replace('//', '/')})
                return true
               
            })
            .catch(error=>{
                console.log(error)
                return false
            })
        },
        reloadDir(context, folder) {
            return context.dispatch('listDir',folder)
        },
        getFile(context,file){
            axios.post("/list",{
                "path":file,
                "type":'file'
                },{
                headers: {
                    "Authorization":context.state.jwt.token,
                    "Content-Type":"application/json"
                }
            }).then(response => {
                const filename = response.headers['content-disposition'].split('=')[1].split("'").pop()
                let link = document.createElement('a');
                link.href=response.data
                link.download = filename;
                document.body.appendChild(link);
                link.click()
                document.body.removeChild(link);
            }).catch(error=>{
                console.log(error)
                return false
            })

        },

        // getFile(context, file) {
        //     EventBus.$emit('downloadFile')
        //     return context.dispatch('_getFile', {
        //         file,
        //         'nocache': false
        //     })
        // },
        // _listDir(context, {
        //     folder,
        //     nocache
        // }) {
        //     context.commit('setPath', {
        //         'path': folder.replace('//', '/')
        //     })
        //     return listDir(context.state.path, context.state.jwt.token, nocache)
        //         .then(response => {
        //             context.commit('setFiles', response.data)
        //             EventBus.$emit('changedFolder')
        //         })
        //         .catch(error => {
        //             console.log('Error listing: ', error)
        //             alert("Impossibile accedere ai file, riprovare più tardi")
        //             EventBus.$emit('errorListing', error)
        //         })
        // },
        // _getFile(context, {
        //     file,
        //     nocache
        // }) {
        //     const filePath = (context.state.path + file).replace('//', '/');
        //     return getFile(filePath, context.state.jwt.token, nocache)
        //         .then(response => {
        //             const filename = response.headers['content-disposition'].split('=')[1].split("'").pop()
        //             let blob = new Blob([response.data], {
        //                 type: response.headers['content-type']
        //             });
        //             let link = document.createElement('a');
        //             link.href = window.URL.createObjectURL(blob);
        //             link.download = filename;
        //             document.body.appendChild(link);
        //             link.click();
        //             document.body.removeChild(link);
        //         })
        //         .catch(error => {
        //             console.log('Error get file: ', error)
        //             alert("Impossibile scaricare il file,riprovare più tardi")
        //             EventBus.$emit('errorDownload', error)
        //         })
        // },
        logout(context) {
            Auth.signOut()
            context.commit('setJwtToken', {
                'jwt': {
                    'token': ''
                }
            })
        },
        getDict(context){
            var defaultDict,defaultCustom,browserDict,browserCustom
            var lang = (navigator.language || navigator.userLanguage).split("-")[0] 

            //loading default languages first (template and user custom)
            return context.dispatch('getSystemFile','templates/base/defaults-all.json')
            .then((response)=>{
                defaultDict = response
                return context.dispatch('getSystemFile','data-'+context.state.settings['site_language']+'.json')

            })
            //override with browser language if present
            .then((response)=>{
                defaultCustom = response
                return context.dispatch('getSystemFile','templates/base/defaults-'+lang+'.json')
            })
            .then((response)=>{
                browserDict = response
                return context.dispatch('getSystemFile','data-'+lang+'.json')
            })
            .then((response)=>{
                browserCustom = response
                context.state.dict = Object.assign({},defaultDict,defaultCustom,browserDict,browserCustom)
                return response
            })
        },
        getSettings(context){
            return context.dispatch('getSystemFile','settings.json')
            .then((response)=>{
                context.state.settings = response
                context.state.noPassLogin = response['loginUserOnly']
                context.state.hideUser = response['loginUserHide']
                return response
            })
        },
        getSystemFile(context,file){
            return axios.get("/"+file)
            .then(response => {
                return response.data
            })
            .catch(() => {
                return {}
            })
        }
    }
});
