import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Login from '../views/login.vue'
import Files from '../views/files.vue'

Vue.use(VueRouter)

const routes= [
    {
        path: '/login',
        name: 'login',
        component: Login
    }, 
    {
        path: '*',
        name: 'files',
        component: Files,
        beforeEnter(to, from, next) {
            if (!store.getters.isAuthenticated) {
                next('/login')
            } else {
                next()
            }
        }
    }]

var router = new VueRouter({
    routes
})

export default router