<template>
  <v-container fluid fill-height class="p" style="background-color:#ffffff">
    <v-layout flex align-center justify-center>
      <v-flex xs12 sm4 elevation-6>
        <v-card >
          <v-img src="/assets/logo.png" alt="logo" height="200px" contain :style="{background:$vuetify.theme.themes.light.primary}"></v-img> 

          <v-card-text class="pt-4">
            <div>
              <v-form v-model="valid" ref="form" >
                <v-text-field
                  v-if="hideUser"
                  :label="dict['user_label']"
                  v-model="user"
                  :rules="userRules"
                  required
                  :append-icon="e1 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (e1 = !e1)"
                  @keyup.enter="authenticate"
                  :type="e1 ? 'password' : 'text'"
                ></v-text-field>
                <v-text-field
                  v-else
                  :label="dict['user_label']"
                  v-model="user"
                  @keyup.enter="authenticate"
                  :rules="userRules"
                  required
                ></v-text-field>
                <v-text-field
                  v-if="passLogin"
                  :label="dict['pass_label']"
                  v-model="password"
                  min="8"
                  :append-icon="e2 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (e2 = !e2)"
                  :type="e2 ? 'password' : 'text'"
                  :rules="passwordRules"
                  @keyup.enter="authenticate"
                  counter
                  required
                ></v-text-field>
                <v-layout justify-space-between>
                  <v-btn
                    @click="authenticate"
                    :class=" { 'primary':valid && !$store.state.settings.dark, 'primary white--text' : valid && $store.state.settings.dark, disabled: !valid }"
                  >{{dict['button']}}</v-btn>
                </v-layout>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify'

export default({
  name:"Login",
  data: function() {
    return {
      valid: false,
      e1: true,
      e2: true,
      dict: this.$store.state.dict,
      user: "",
      // userRules: [v => !!v || this.$store.state.dict['user_error']],
      userRules: [v => !!v || 'utente non valido'],
      password: "",
      // passwordRules: [v => !!v || this.$store.state.dict['pass_error']],
      passwordRules: [v => !!v || "password non valida"],
      errorMsg: "",
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 8 || "Min 8 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },

    };
  },
  computed: {
    passLogin() {
      return !this.$store.state.noPassLogin;
    },
    hideUser() {
      return this.$store.state.hideUser;
    }
  },
  methods: {
    authenticate() {
      if (this.$refs.form.validate()) {
        var username = localStorage.accountant+"/"+this.user
        if(!this.passLogin)
          this.password = this.user
        Auth.signIn(username,this.password)
          .then(user =>{
            console.log(user)
            this.$store.commit('setUser',this.user)
            this.$store.commit('setJwtToken',{'token':user.signInUserSession.idToken.jwtToken})
            //TODO location.href
            this.$router.push('/')
          })
          //eslint-disable-next-line
          .catch(error => {
            console.log(error)
            alert("Username o Password errati")
            this.error = true
            self.loading = false
          })
      }
    }
  },
  mounted:
    function() {
      // EventBus.$on("failedAuthentication", msg => {
      //   this.errorMsg = msg.response.data.message;
      // });
      if (this.$store.getters.isAuthenticated) this.$router.push("/");
    },
  beforeDestroy: function() {
    // EventBus.$off("failedAuthentication");
  }
});
</script>
