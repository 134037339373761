<template v-slot:activator="{on}">
  <v-layout style="background-color:#ffffff; padding-bottom:60px">
    <v-flex xs12 sm6 offset-sm3>
      <v-toolbar fixed class="elevation-1" :dark="$store.state.settings.dark" color="primary" v-if="!showSearch">
        <v-btn icon @click="$router.push(prevFolder.path)">
          <v-icon v-if="pathItems.length > 1">arrow_back</v-icon>
          <v-avatar v-else :size="32">
            <img src="/assets/logo-icon.png" alt="Logo">
          </v-avatar>
        </v-btn>
        <v-toolbar-title>{{ currFolder.name || dict['file_header'] }}</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" @click="showSearch = true">
              <v-icon>search</v-icon>
            </v-btn>
          </template>
          <span>{{dict['search_text']}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" @click="refresh">
              <v-icon>refresh</v-icon>
            </v-btn>
          </template>
          <span>{{dict['refresh_text']}}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" @click="showExitDialog = true">
              <v-icon>exit_to_app</v-icon>
            </v-btn>
          </template>
          <span>{{dict['exit_confirm']}}</span>
        </v-tooltip>
      </v-toolbar>
      <v-toolbar fixed color="white" v-if="showSearch">
        <v-btn icon @click="showSearch = false">
          <v-icon>arrow_back</v-icon>
        </v-btn >
        <v-text-field
          autofocus
          single-line
          full-width
          hide-details
          v-model="searchText"
          :placeholder="dict['search_text']"
          clearable
        ></v-text-field>
      </v-toolbar>
      <v-card text class="mt-md-5">
        <v-fade-transition>
          <v-list two-line>
            <v-subheader>
              <v-breadcrumbs :items="pathItems">
                <v-icon slot="divider">navigate_next</v-icon>
                <template slot="item" slot-scope="props">
                  <router-link :to="props.item.path" :class="[props.item.disabled && 'disabled']" @click="$store.dispatch('listDir',props.item.path)">
                    <v-icon
                      :color="props.item.disabled? 'grey':'primary'"
                      v-if="props.item.icon"
                    >{{props.item.icon}}</v-icon>
                    {{ props.item.name }}
                  </router-link>
                </template>
              </v-breadcrumbs>
              <v-spacer></v-spacer>
              <v-bottom-sheet inset v-model="sorters.show">
                <template v-slot:activator="{on}">
                  <v-btn icon text small v-on="on">
                    <v-icon>filter_list</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-subheader>{{dict['order_by']}}</v-subheader>
                  <v-list-item
                    v-for="sorter in sorters.types"
                    :key="sorter[sortKey]"
                    @click="updateSortKey(sorter.key)"
                  >
                    <v-list-item-avatar>
                      <v-icon>{{sorter.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ sorter.label }}
                        <v-icon
                          v-if="sortKey == sorter.key"
                        >{{sortDirAsc? 'arrow_drop_up':'arrow_drop_down'}}</v-icon>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon v-if="sortKey == sorter.key" color="teal">check</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-bottom-sheet>
            </v-subheader>
            <template v-if="!loading">
              <v-list-item
                ripple
                v-for="item in items"
                :key="item.name"
                @click="openItem(item)"
              >
                <v-list-item-avatar>
                  <v-icon
                    class="grey lighten-1 white--text"
                    :class="[item.name.split('.').pop()]"
                  >{{ item.type == 'dir'? 'folder':'insert_drive_file' }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.size | formatFileSize }}, modifica {{ item.date | formatFileDate }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1">more_vert</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-fade-transition>
      </v-card>
      <v-card class="transparent text-xs-center pa-5" text v-if="loading">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-card>
      <v-dialog v-model="showExitDialog" max-width="290">
        <v-card>
          <v-card-title>{{dict['exit_text']}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showExitDialog = false">{{dict['cancel']}}</v-btn>
            <v-btn text @click="logout" color="primary">{{dict['exit_confirm']}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>

<script>

import moment from 'moment'

export default({
  name:"Files",
  data: function() {
    return {
      loading: true,
      searchText: "",
      dict: this.$store.state.dict,
      sorters: {
        show: false,
        types: [
          { label: this.$store.state.dict['order_by_name'], icon: "sort_by_alpha", key: "name" },
          { label: this.$store.state.dict['order_by_date'], icon: "access_time", key: "date" },
          { label: this.$store.state.dict['order_by_dimension'], icon: "fullscreen", key: "size" }
        ]
      },
      showExitDialog: false,
      showSearch: false
    };
  },
  filters: {
    formatFileSize: function(size) {
      if (!Number.isFinite(size)) return "";

      var UNITS = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      var i = 0;
      do {
        size /= 1024;
        i++;
      } while (size > 1024);

      return Math.max(size, 0.1).toFixed(1) + " " + UNITS[i];
    },
    formatFileDate: function(date) {
      return moment(date, "X")
        .calendar()
        .toLowerCase();
    }
  },
  computed: {
    sortKey: {
      get: function() {
        return this.$store.state.sortKey;
      },
      set: function(key) {
        this.$store.commit("setSortKey", key);
      }
    },
    sortDirAsc: {
      get: function() {
        return this.$store.state.sortDirAsc;
      },
      set: function(dirAsc) {
        this.$store.commit("setSortDirAsc", dirAsc);
      }
    },
    items() {
      return this.$store.state.files
        .filter(item => {
          return item.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase() || "");
        })
        .sort((a, b) => {
          if(typeof(a[this.sortKey])=='string'){
              if (this.sortDirAsc) {
                  if (a[this.sortKey].toLowerCase() < b[this.sortKey].toLowerCase())
                    return -1;
                  if (a[this.sortKey].toLowerCase() > b[this.sortKey].toLowerCase())
                    return 1;
              }
              else {
                  if (a[this.sortKey].toLowerCase() < b[this.sortKey].toLowerCase())
                    return 1;
                  if (a[this.sortKey].toLowerCase() > b[this.sortKey].toLowerCase())
                    return -1;
                }
              }
          else{
            if (this.sortDirAsc){
                  if (a[this.sortKey] < b[this.sortKey])
                    return -1;
                  if (a[this.sortKey] > b[this.sortKey])
                    return 1;
            }
            else {
                  if (a[this.sortKey] < b[this.sortKey])
                    return 1;
                  if (a[this.sortKey] > b[this.sortKey])
                    return -1;
                  }
              }
          return 0;
          });
    },
    pathItems() {
      var pathItems = [];
      var path = "";
      if (this.$store.state.path.length > 1)
        this.$store.state.path
          .split("/")
          .slice(1)
          //eslint-disable-next-line
          .forEach((folder, i) => {
            path += "/" + folder;
            pathItems.push({ name: folder, path: path });
          });
      pathItems.unshift({ icon: "home", name: "", path: "/" });
      pathItems[pathItems.length - 1].disabled = true;

      return pathItems;
    },
    currFolder() {
      return this.pathItems[this.pathItems.length - 1];
    },
    prevFolder() {
      if (this.pathItems.length > 1)
        return this.pathItems[this.pathItems.length - 2];
      else return this.pathItems[this.pathItems.length - 1];
    }
  },
  methods: {
    refresh() {
      this.loading = true;
      this.$store.dispatch("reloadDir", this.$store.state.path);
      this.loading= false
    },
    openItem(item) {
      if (item.type == "dir"){
        this.$router.push(
          (this.$store.state.path + "/" + item.name).replace("//", "/")
        );
        this.loading=true
        this.$store.dispatch('listDir', (this.$store.state.path+"/"+item.name).replace("//", "/"))
        .then(this.loading=false)
      }
      else
        this.$store.dispatch(
          "getFile",
          (this.$store.state.path+"/" + item.name).replace("//", "/")
        );
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    updateSortKey(sortKey) {
      if (this.sortKey == sortKey) {
        this.sortDirAsc = !this.sortDirAsc;
      } else {
        this.sortDirAsc = true;
        this.sortKey = sortKey;
      }
      this.sorters.show = false;
    }
  },
  mounted: function() {
    // EventBus.$on("changedFolder", () => {
    //   this.loading = false;
    //   //EventBus.$off('changedFolder')
    // });
    moment.locale('it')
    this.loading = true;
    console.log(this.loading+Date.now());
    this.$store.dispatch('listDir', (this.$route.path).replace("//", "/"))
    .then(this.loading = false)
    console.log(this.loading+Date.now());
    
  }
});
</script>

<style>
.v-breadcrumbs a {
  text-decoration: none;
}
.disabled {
  color: grey;
  pointer-events: none;
}
</style>
