import Vue from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify';
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import config from '../../config.json'

Amplify.configure({
  Auth:{
    mandatorySignId:true,
    region:config.Region,
    userPoolId:config.ClientsUserPoolId,
    userPoolWebClientId:config.UserPoolClientId,
    identityPoolId:config.IdentityPoolId
  }
})
Vue.config.productionTip = false


store.dispatch('getSettings')
//eslint-disable-next-line
.then((response)=>{
  return store.dispatch('getDict')
})
//eslint-disable-next-line
.then((response)=>{
  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})



