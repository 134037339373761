<template>
  <div id="app">
    <v-app>
      <router-view :key="$route.fullPath"></router-view>
      <v-footer absolute v-bind:style="{ backgroundColor: '#fafafa', color: '#999'}" class="body-2">
        <v-col class="text-center" cols="12">
          <span v-html="$store.state.dict.credits"></span>
        </v-col>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
  created: function(){
    Object.keys(this.$store.state.settings.colors).forEach((key)=>{
      this.$vuetify.theme.themes.light[key] = this.$store.state.settings.colors[key]
    });
  }
}
</script>

